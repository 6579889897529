import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaStar } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Accueil | Tovirex Pride Pneus
			</title>
			<meta name={"description"} content={"Conduisez le kilomètre supplémentaire en toute confiance!"} />
			<meta property={"og:title"} content={"Accueil | Tovirex Pride Pneus"} />
			<meta property={"og:description"} content={"Conduisez le kilomètre supplémentaire en toute confiance!"} />
			<meta property={"og:image"} content={"https://tovirexpride.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tovirexpride.com/img/3257257.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tovirexpride.com/img/3257257.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tovirexpride.com/img/3257257.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tovirexpride.com/img/3257257.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tovirexpride.com/img/3257257.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tovirexpride.com/img/3257257.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://tovirexpride.com/img/1.jpg) center center/cover no-repeat"
			min-height="75vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Tovirex Pride Pneus
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Bienvenue chez Pneus Tovirex Fierté, votre destination ultime pour des services et des produits de pneus de première qualité. Que vous vous prépariez pour un voyage en voiture ou que vous vouliez vous assurer que vos déplacements quotidiens se déroulent le mieux possible, nous avons ce qu'il vous faut. Notre équipe se consacre à vous fournir des conseils professionnels et un service exceptionnel, afin que votre véhicule soit équipé pour affronter toutes les aventures.
				</Text>
				<Link
					href="/contact"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-primary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Contactez-nous
				</Link>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					md-text-align="center"
					margin="0px 0px 16px 0px"
					font="--base"
					color="--primary"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					Chez Pneus Tovirex Pride
				</Text>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Pourquoi choisir Tovirex Pride?
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
				align-items="center"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaStar}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Une qualité incontestable: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Nous disposons d'une large sélection de pneus des principaux fabricants, ce qui garantit la durabilité, la sécurité et la performance.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaStar}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Un service avec le sourire:
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Nous croyons qu'il faut rendre votre expérience d'achat et d'entretien de pneus aussi agréable que possible. Attendez-vous à des visages amicaux et à un processus sans tracas à chaque étape.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaStar}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Des prix transparents:
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Avec nous, ce que vous voyez est ce que vous obtenez. Profitez de prix compétitifs sans frais cachés.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaStar}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
						text-align="center"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Une expertise en laquelle vous pouvez avoir confiance:
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Nos techniciens ne sont pas seulement des passionnés de pneus - ce sont des experts dans leur domaine, prêts à vous guider pour trouver le pneu idéal pour votre véhicule et vos besoins de conduite.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box border-radius="24px" margin="0px 0px 40px 0px">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Prenez contact avec nous
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Rendez-vous dès aujourd'hui chez Tovirex Pride Tires, où les meilleurs jours de votre véhicule vous attendent!
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://tovirexpride.com/img/5.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://tovirexpride.com/img/6.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Nous sommes impatients de vous aider à trouver les pneus parfaits pour votre véhicule et de veiller à ce qu'il fonctionne au mieux. Votre voyage vers une conduite plus sûre et plus fiable commence ici.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});